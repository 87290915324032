import { get as getStore } from 'svelte/store'
import { _, isLoading, locale } from 'svelte-i18n'

const readTranslatedKey = (key, valuesObj) => {
    const localeExists = getStore(locale)
    const localeLoaded = !getStore(isLoading)
    if (localeExists && localeLoaded) {
        const translate = getStore(_)
        return translate(key, valuesObj)
    }
}

export { readTranslatedKey }
