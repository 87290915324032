const clearLocalAndSessionStorage = () => {
    const plausible_ignore = localStorage.getItem('plausible_ignore')
    const language = localStorage.getItem('lang')
    localStorage.clear()
    if (plausible_ignore) {
        localStorage.setItem('plausible_ignore', true)
    }
    if (language) {
        localStorage.setItem('lang', language)
    }
    sessionStorage.clear()
}

const clearLocalStorage = (ignoreValues = []) => {
    for (const [key] of Object.entries(localStorage)) {
        if (ignoreValues.includes(key)) continue
        localStorage.removeItem(key)
    }
}

const clearSessionStorage = (ignoreValues = []) => {
    for (const [key] of Object.entries(sessionStorage)) {
        if (ignoreValues.includes(key)) continue
        sessionStorage.removeItem(key)
    }
}

export { clearLocalAndSessionStorage, clearLocalStorage, clearSessionStorage }
