import { global } from '$src/state.svelte.js'
import { countdown } from '$src/stores.js'

const moduleFetchErrors = [
    'Failed to fetch dynamically imported module', //chrome
    'Importing a module script failed', // firefox
    'error loading dynamically imported module', //safari
]

// this fn gets called onerror, onunhandledrejection
const handleUnrecoverableError = async (err) => {
    const error =
        err?.error || // onerror - sync error
        err?.reason // unhandledrejection - async / promise error
    const stack = error?.stack

    const message = error?.message
    const moduleFetchFailed =
        message && moduleFetchErrors.some((msg) => message.includes(msg))

    // safari webkit extensions errors send a stack -- ignore this
    const webkitBrowserExtError =
        (typeof error === 'string' &&
            error?.includes('@webkit-masked-url://hidden/')) ||
        (typeof stack === 'string' &&
            stack?.includes('@webkit-masked-url://hidden/'))

    // https://issuetracker.google.com/issues/396043331?pli=1
    // https://stackoverflow.com/questions/79434372/sentry-errors-variable-not-found-gmo-invalid-or-unexpected-token-due-to-go/79434491#79434491
    const googleSearchAppIOSBug =
        typeof message === 'string' &&
        message.includes("Can't find variable: gmo")

    if (!stack) {
        // we ignore extension errors and many extensions don't have a stack,
        console.error('Error from third-party script', err)
        return
    }

    const errObj = {
        message,
        reason: err.reason,
        err,
        error,
        errJSON: JSON.parse(JSON.stringify(err)),
        errorJSON: JSON.parse(JSON.stringify(error)),
    }

    // prep message to send
    const globalData = global.data // response from GET /profile | GET /consent
    const body = {
        message,
        stack,
        err: errObj,
        state: {
            page: window.location.href,
            referrer: document.referrer || 'UNKNOWN',
            globalData,
            storage: {
                session: window.sessionStorage,
                local: window.localStorage,
            },
            navigator: {
                ua: window.navigator.userAgent,
                languages: window.navigator.languages,
            },
        },
    }
    /*
        can't use fetch.js send() here
        as that might be why we are here
        which might put us in an infinite loop of failures
    */
    const postError = async (_body) => {
        try {
            await fetch('/api/v1/client/error', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(_body),
            })
        } catch (err) {
            console.error('failed to post error to slack', err)
        }
    }

    postError(body)

    if (webkitBrowserExtError) {
        // ignore webkit extensions
        console.error('Error from webkit extension', err)
        return
    }

    if (googleSearchAppIOSBug) {
        // ignore google search app throw on ios app
        console.error('Error from google search app', err)
        return
    }

    // fetchContext obj is added to error obj in fetch.js
    if (error?.fetchContext?.status && error?.fetchContext?.status >= 500) {
        countdown.clear() // dont want session timer modal to show up
        global.serverErrorModal = true
    } else {
        global.clientErrorModal = true
    }

    // handle module fetch fail errors
    if (moduleFetchFailed) {
        // if module fetch fails, try to fetch version and cloudfront pop response header to report to slack
        const tryAgain = window.confirm(
            'One or more resources in this app failed to load. Would you like to try reloading the page?',
        )
        if (tryAgain) {
            await postError({
                ...body,
                stack: 'User opted to reload page - ' + stack,
            })
            return window.location.reload()
        } else {
            return await postError({
                ...body,
                stack: 'User opted not to reload page - ' + stack,
            })
        }
    }
}

export { handleUnrecoverableError }
