<script>
    import { fade, fly } from 'svelte/transition'
    import { SLIDE_ANIM_MS } from '$src/constants.js'

    let {
        dataTest = '',
        css = '',
        modalCss = '',
        children,
        close = () => {},
    } = $props()
</script>

<svelte:window
    onkeydown={(e) => {
        if (e.key === 'Escape') {
            close(e)
        }
    }}
/>

<div class="relative z-50" role="dialog" aria-modal="true">
    <div
        class="bg-charcoal fixed inset-0 bg-opacity-80 transition-opacity"
        transition:fade={{ duration: SLIDE_ANIM_MS }}
    ></div>
    <div
        class="fixed inset-0 z-10 w-full overflow-y-auto {css}"
        transition:fly={{ y: 10, duration: SLIDE_ANIM_MS }}
    >
        <div
            class="flex min-h-full w-full items-center justify-center p-4 text-center sm:p-0"
        >
            <div
                class="relative w-full transform overflow-hidden rounded-lg p-1 transition-all sm:my-12 sm:max-w-md {modalCss}"
            >
                <div
                    data-test={dataTest}
                    class="flex flex-col items-center rounded-lg border border-white bg-white px-4 pb-4 pt-5 sm:p-6 dark:border-[#808080] dark:bg-[#151515]"
                >
                    {@render children?.()}
                </div>
            </div>
        </div>
    </div>
</div>
